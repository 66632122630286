import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Navbar from '../components/header'
import Footer from '../components/footer'
import Snowfall from 'react-snowfall'
import SiteSettings from "/src/site-settings.json"
import CookieBanner from "../components/cookie_banner"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import Easter from "../components/easter"

import Seo from "../components/seo"

export default function IndexPage() {

  // if xmas is activated in site-settings.json activate snowfall
  var snowfall = "";
  if (SiteSettings.xmas_theme) {
    snowfall = <Snowfall />
  }

  return (

    <div className="bg-def_grey_1">

      {/* background image with text*/}
      <div className="index_typography flex flex-col items-center">
        <StaticImage className="brightness-50 h-screen" src="../images/beach_blurred2.jpg" placeholder="blurred" alt="" ></StaticImage>
        {snowfall}

        <div className="h-2 w-full bg-gradient-to-r from-def_yellow_1 to-transparent"></div>

        <div className="absolute mx-10 flex flex-col items-center top-1/3 max-w-lg ">

          <h1 className="text-center">Vårt syfte är att berika studentlivet och upprätthålla en god gemenskap. <Easter text="🐣🥚"/></h1>
          <a className="button_1 mt-8" href="https://hitract.se/hitclub/577" target="blank">Bli medlem</a>
        </div>
      </div>

      <Navbar />

      <main className="index_typography flex flex-col items-center min-w-full min-h-screen">



        <div className="section_container">

          <section>
            <div className="section_content">
              <h2>Om Ödet<FontAwesomeIcon className="text-md pl-4" icon={solid('flag')} /></h2>
              <p>ÖDET Programförening är en ideell förening för de som studerar Data-, Civildata-, Elektro-, Mekatronikingenjörer och Civilingenjör i intelligenta system (elektronik) vid Högskolan i Halmstad</p>
              <Link to="/om">Läs mer om ÖDET</Link>
            </div>
          </section>

          <section>
            <div className="section_content">
              <h2>Märken och tröjor<FontAwesomeIcon className="text-md pl-4" icon={solid('shirt')} /></h2>
              <p>Här lägger vi upp alla märken som finns till försäljning för våra medlemmar. Skicka ett DM på instagram eller ett mail till ledamot.iom@odethh.se om du vill köpa något så bestämmer vi en tid vi kan mötas upp på D2. Betalning sker via Swish.</p>
              <Link to="/merch">Gå till Merch</Link>
            </div>
          </section>

          <section>
            <div className="section_content">
              <h2>Varför bli medlem?<FontAwesomeIcon className="text-md pl-4" icon={solid('star')} /></h2>
              <p>Förutom att du bidrar till en god gemenskap får du tillgång till massor av roliga gasquer och andra events. Du får även tillgång till kaffemaskinen på D2.</p>
              <Link to="/medlemskap">Läs mer om Medlemskap</Link>
            </div>
          </section>

          <section>
            <div className="section_content">
              <h2>Phestkommittén<FontAwesomeIcon className="text-md pl-4" icon={solid('hat-wizard')} /></h2>
              <p>ÖDETs phestkommitté, också kallat Saft och Bullar, är ett organ som hjälper till att planera och anordna event så som gasquer, sittningar, pub-kvällar och annat dylikt tillsammans med andra program.
                <br /><br />
                Ni kanske har hört talas om den 7kt coola MÖS-gasquen tillsammans med Maskin och Sjuk, eller kanske den 7kt heta Het-gasquen tillsammans med U, SOK, OLAV/SOSH och Maskin? Detta är bara ett par av alla våra spexiga event som vi varit med och planerat. </p>
              <Link to="/phestkommitten">Läs mer om Phestkommittén</Link>
            </div>
          </section>

        </div>

      </main>

      <Footer></Footer>
      <CookieBanner></CookieBanner>

    </div>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Hem" />
